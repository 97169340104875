import { Trans as ReactI18nTrans } from "next-i18next";
import { Link } from "@mui/material";

export type TransProps = {
  i18nKey: string;
  count?: number;
  ns?: string;
  components?:
    | readonly React.ReactElement[]
    | { readonly [tagName: string]: React.ReactElement };
};

export default function Trans({ components, ...props }: TransProps) {
  return (
    <ReactI18nTrans
      {...props}
      components={{
        a: <Link />,
        ...(components ?? {}),
      }}
    />
  );
}
