"use client";

import {
  Box,
  Container,
  Grid,
  Link,
  Theme,
  Typography,
  styled,
  useMediaQuery,
} from "@mui/material";
import { useTranslation } from "next-i18next";
import slugify from "slugify";
import { GeoZone } from "@serviestate/shared";
import { useRef } from "react";

export type ZoneItem = {
  id: string;
  image_id: string;
  detail: GeoZone;
  property_count: number;
};

export type ZonesCollageProps = {
  zones: ZoneItem[];
};

export default function ZonesCollage({ zones }: ZonesCollageProps) {
  return (
    <Container>
      <Grid container spacing={3.5}>
        {zones.map((zone, i) => (
          <Grid
            key={`zone_${zone.id}`}
            item
            xs={12}
            md={
              i + 1 === zones.length && zones.length % 2 !== 0
                ? 12
                : [0, 3].includes(i % 4)
                ? 4
                : 8
            }
          >
            <ZoneItem
              zone={zone}
              size={
                i % 2 === 0 && i === zones.length - 1
                  ? "large"
                  : ![0, 3].includes(i % 4)
                  ? "medium"
                  : "small"
              }
            />
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}

function ZoneItem({
  zone,
  size,
}: {
  size: "small" | "medium" | "large";
  zone: ZoneItem;
}) {
  const { t, i18n } = useTranslation();
  const isMdUp = useMediaQuery<Theme>((theme) => theme.breakpoints.up("md"));
  const linkRef = useRef<HTMLAnchorElement>(null);

  const zoneCoverUrl = `/api/documents/${zone.image_id}?fit=cover&format=webp${
    isMdUp
      ? size === "large"
        ? "&width=1200&height=360"
        : size === "medium"
        ? "&width=760&height=360"
        : "&width=370&height=360"
      : "&width=370&height=360"
  }`;

  const url = `/${i18n.language}/${slugify(`${t("transaction.type_2")}`, {
    lower: true,
  })}?geo-zone-id=${zone.detail?.id ?? ""}`;

  return (
    <Link
      ref={linkRef}
      href={url}
      sx={{
        height: "100%",
        display: "block",
        textDecoration: "none",
        color: (theme) => theme.palette.common.white,
        backgroundColor: (theme) => theme.palette.grey[700],
        borderRadius: 1.5,
        overflow: "hidden",
        position: "relative",
        "&:hover": {
          "& img": {
            transform: "scale(1.1) rotate(-1deg)",
          },
        },
      }}
    >
      <CoverImage src={zoneCoverUrl} />
      <Box
        sx={{
          background: "linear-gradient(180deg,hsla(0,0%,100%,.01),#1d293e)",
          position: "absolute",
          bottom: 0,
          height: "30%",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-end",
          alignItems: "center",
          pb: 3,
        }}
      >
        {zone.detail && (
          <Typography fontWeight="bold" fontSize={18} gutterBottom>
            {zone.detail.computed_name}
          </Typography>
        )}
        {zone.property_count > 0 && (
          <Typography>
            {zone.property_count}&nbsp;
            {t("property", { count: zone.property_count })}
          </Typography>
        )}
      </Box>
    </Link>
  );
}

const CoverImage = styled("img")({
  display: "block",
  width: "100%",
  height: "auto",
  transition: "all .6s ease",
});
