import { PropertyProfile } from "@serviestate/shared";
import { useTranslation } from "next-i18next";
import slugify from "slugify";

export function usePropertyLink(item: PropertyProfile) {
  const { t, i18n } = useTranslation();
  return `/${i18n.language}/${slugify(t("property"))}/${encodeURIComponent(
    item.reference
  )}`;
}
