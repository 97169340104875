"use client";

import { Box, Container } from "@mui/material";

import Slider, { Settings } from "react-slick";

import { useMemo } from "react";
import { PropertyProfile } from "@serviestate/shared";
import { PropertyCard } from "../properties/property-card";

export default function PropertiesHighlights({
  properties,
}: {
  properties: PropertyProfile[];
}) {
  const settings: Settings = useMemo(
    () => ({
      dots: true,
      arrows: false,
      infinite: true,
      speed: 1200,
      slidesToShow: properties.length > 3 ? 3 : properties.length,
      slidesToScroll: 1,
      centerMode: false,
      autoplay: false,
      lazyLoad: "progressive",
      responsive: [
        {
          breakpoint: 900,
          settings: {
            slidesToShow: properties.length > 2 ? 2 : properties.length,
          },
        },

        { breakpoint: 600, settings: { slidesToShow: 1 } },
      ],
    }),
    [properties]
  );

  return (
    <Container
      maxWidth="lg"
      sx={{
        mb: 6,
        "& .slick-slide": { margin: "0 auto", height: "inherit" },
        "& .slick-slide > div": { margin: "0 auto", height: "100%" },
        "& .slick-track": {
          display: "flex",
          justifyContent: "center",
        },
      }}
    >
      <Slider {...settings}>
        {properties.map((x, i) => (
          <Box
            key={`property_${i}`}
            sx={{
              margin: "0 auto",
              height: "100%",
              px: 2,
              pb: 3,
            }}
          >
            <PropertyCard property={x} />
          </Box>
        ))}
      </Slider>
    </Container>
  );
}
