"use client";

import { Box, Container, Typography } from "@mui/material";
import { DynamicTranslation } from "@serviestate/shared";
import { getTranslation } from "../../utils";
import { useTranslation } from "next-i18next";

export type ClaimProps = {
  title: string | DynamicTranslation;
  subtitle: string | DynamicTranslation;
};

export default function Claim({ title, subtitle }: ClaimProps) {
  const { i18n } = useTranslation();
  return (
    <Container sx={{ mt: 10, mb: 3 }}>
      <Typography variant="h4" textAlign="center" gutterBottom>
        {getTranslation(i18n.language, title)}
      </Typography>
      <Typography
        variant="h5"
        textAlign="center"
        color={(theme) => theme.palette.grey[800]}
      >
        {getTranslation(i18n.language, subtitle)}
      </Typography>
    </Container>
  );
}
