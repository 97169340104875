import {
  Box,
  alpha,
  Chip,
  Typography,
  Divider,
  styled,
  Link,
  SxProps,
  Theme,
  Grid,
} from "@mui/material";
import { PropertyProfile } from "@serviestate/shared";
import { useTranslation } from "next-i18next";
import { TfiLocationPin } from "react-icons/tfi";
import { getTranslation, getTranslationFromArray } from "../../utils";
import slugify from "slugify";

export function PropertyCard({
  property,
  transactionTypeId,
  sx,
}: {
  property: PropertyProfile;
  transactionTypeId?: number | null;
  sx?: SxProps<Theme>;
}) {
  const { t, i18n } = useTranslation();
  const translation = getTranslation(i18n.language, property.translations);
  const attributes: string[] = [];
  const bedrooms = parseInt(
    (property.attributes.bedrooms as string) ?? "0",
    10
  );
  const bathrooms = parseInt(
    (property.attributes.bathrooms as string) ?? "0",
    10
  );
  const surface = parseInt((property.attributes.surface as string) ?? "0", 10);

  const transaction =
    (transactionTypeId
      ? property.transactions.find((x) => x.type.id === transactionTypeId)
      : property.transactions.find((x) => x.type.id === 2)) ??
    property.transactions[0];

  if (bedrooms > 0)
    attributes.push(
      bedrooms > 1
        ? `${property.attributes.bedrooms} ${t("attributes.bedrooms")}`
        : `1 ${t("attributes.bedroom")}`
    );

  if (bathrooms > 0)
    attributes.push(
      bathrooms > 1
        ? `${property.attributes.bathrooms} ${t("attributes.bathrooms")}`
        : `1 ${t("attributes.bathroom")}`
    );

  if (surface > 0) attributes.push(`${surface}m2`);

  const photos = property.multimedia.filter((x) => x.render_type === "image");

  const mainPhoto =
    photos.length > 0
      ? `/api/documents/${photos[0].document_id}?fit=cover&width=340&height=240&format=webp`
      : "/assets/no-photo.png";

  const detailUrl = `/${i18n.language}/${slugify(t("property"), {
    lower: true,
  })}/${encodeURIComponent(property.reference)}`;
  return (
    <Link
      href={detailUrl}
      sx={{
        display: "flex",
        flexDirection: "column",
        textDecoration: "none",
        color: "inherit",
        border: (theme) => `1px solid ${theme.palette.divider}`,
        borderRadius: 2,
        maxWidth: 340,
        width: "100%",
        height: "100%",
        margin: "0 auto",
        backgroundColor: (theme) => theme.palette.background.paper,
        transition: (theme) =>
          theme.transitions.create(["box-shadow"], { duration: "0.5s" }),
        "&:hover": {
          boxShadow: (theme) => theme.shadows[3],
          cursor: "pointer",
          "& .title": {
            color: (theme) => theme.palette.primary.main,
          },
        },
        ...sx,
      }}
    >
      <Box
        sx={{
          m: 1,
          borderRadius: 1,
          overflow: "hidden",
          backgroundColo: "#1d293e",
          position: "relative",
          ":before": {
            background: `linear-gradient(180deg,hsla(0,0%,100%,.01),${alpha(
              "#1d293e",
              0.8
            )})`,
            bottom: 0,
            content: '""',
            left: 0,
            position: "absolute",
            right: 0,
            height: "35%",
            zIndex: 1,
          },
        }}
      >
        <CoverImage src={mainPhoto} />
        <Box
          sx={{
            position: "absolute",
            zIndex: 2,
            left: 0,
            top: 0,
            height: "100%",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ display: "flex", gap: 1, p: 2 }}>
            {property.attributes.in_exclusivity === "1" && (
              <Chip label={t(`in_exclusivity`)} size="small" color="primary" />
            )}
            {property.transactions.map((x) => (
              <Chip
                key={`chip_${x.type.id}`}
                label={t(`transaction.type_${x.type.id}`)}
                size="small"
                color="secondary"
              />
            ))}
            {property.status && (
              <Box flex={1} display="flex" justifyContent="flex-end">
                <Chip
                  label={t(`property_status.type_${property.status.id}`)}
                  size="small"
                  color="success"
                />
              </Box>
            )}
          </Box>
          {transaction.price && (
            <Box
              sx={{
                p: 2,
              }}
            >
              <Typography
                letterSpacing={0.75}
                fontSize={24}
                color={(theme) => theme.palette.common.white}
              >
                {transaction.price.toLocaleString("de-DE")}€
              </Typography>
            </Box>
          )}
        </Box>
      </Box>

      <Box
        sx={{
          mx: 1,
          flex: 1,
          display: "flex",
          flexDirection: "column",
          gap: 1,
          padding: 1,
        }}
      >
        <Typography
          sx={{
            color: (theme) => theme.palette.primary.main,
            fontSize: "14px",
          }}
        >
          {getTranslationFromArray(i18n.language, property.type.translations)
            ?.name ?? property.type.name}
          {property.type.name !== property.subtype.name &&
            ` / ${
              getTranslationFromArray(
                i18n.language,
                property.subtype.translations
              )?.name ?? property.subtype.name
            }`}
        </Typography>
        <Typography
          className="title"
          fontWeight="700"
          fontSize={18}
          sx={{
            transition: (theme) =>
              theme.transitions.create("color", { duration: "0.5s" }),
          }}
        >
          {translation?.title}
        </Typography>
        {property.zone && (
          <Typography
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 0.5,
              fontSize: "14px",
              my: 1,
            }}
          >
            <TfiLocationPin size={14} /> {property.zone.computed_name}
          </Typography>
        )}
        <Grid container spacing={3}>
          {attributes.map((x, i) => (
            <Grid item key={i}>
              <Typography>{x}</Typography>
            </Grid>
          ))}
        </Grid>
      </Box>

      <Divider sx={{ my: 1 }} />
      <Box sx={{ mb: 1, px: 1 }}>
        <Typography variant="body2">
          {t("filter.reference")}: {property.reference}
        </Typography>
      </Box>
    </Link>
  );
}

const CoverImage = styled("img")({
  display: "block",
  objectFit: "fill",
  width: 340,
  height: 240,
});
