"use client";

import { Container } from "@mui/material";
import { DynamicTranslation } from "@serviestate/shared";
import { getTranslation } from "../../utils";
import { useTranslation } from "next-i18next";

export type ContentProps = {
  content: string | DynamicTranslation;
};

export default function Content({ content }: ContentProps) {
  const { i18n } = useTranslation();
  const translatedContent = getTranslation(i18n.language, content) ?? "";
  return (
    <Container
      sx={{
        my: 3,
        "& img": { maxWidth: "100%" },
        "& figure": {
          textAlign: "center",
          "&.image-style-side": {
            textAlign: "right",
          },
        },
      }}
    >
      <div dangerouslySetInnerHTML={{ __html: translatedContent }}></div>
    </Container>
  );
}
