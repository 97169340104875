import {
  Box,
  Button,
  FormControl,
  InputLabel,
  ListSubheader,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { Fragment, useCallback, useMemo } from "react";
import { useRouter } from "next/router";
import slugify from "slugify";
import { useAgency } from "../../hooks/use-agency";
import { useTranslation } from "next-i18next";
import { getTranslationFromArray } from "../../utils";
import { GeoZone } from "@serviestate/shared";

export default function Searcher() {
  const { t, i18n } = useTranslation();
  const router = useRouter();
  const agency = useAgency();
  const { control, register, handleSubmit } = useForm({
    defaultValues: {
      transaction_type_id: agency.info.transactions[0]?.id,
      property_type_id: "all",
      zone: "all",
      reference: "",
    },
  });

  const zonesGrouped = useMemo(() => {
    const groups = agency.info.geo_zones.reduce((acc, zone) => {
      const level5 = zone.components.find((x) => x.level.level === 5);
      if (!level5) return acc;
      if (!acc[level5.id]) acc[level5.id] = { component: level5, zones: [] };
      acc[level5.id].zones.push(zone);
      return acc;
    }, {} as Record<string, { component: GeoZone; zones: GeoZone[] }>);
    return Object.keys(groups).map((x) => groups[x]);
  }, [agency]);

  const handleSearch = useCallback(
    (form: any) => {
      if (form.reference) {
        return router.push(
          `/${i18n.language}/${slugify(t("property"), {
            lower: true,
          })}/${encodeURIComponent(form.reference)}`
        );
      }

      const transactionSlug = slugify(
        t(`transaction.type_${form.transaction_type_id}`),
        { lower: true }
      );

      const params: Record<string, string> = {};

      if (form.property_type_id && form.property_type_id !== "all")
        params["type-id"] = form.property_type_id;

      if (form.zone && form.zone !== "all") params["geo-zone-id"] = form.zone;

      router.push(
        `/${i18n.language}/${transactionSlug}?${new URLSearchParams(
          params
        ).toString()}`
      );
    },
    [router, t, i18n]
  );

  return (
    <form onSubmit={handleSubmit(handleSearch)}>
      <Box
        sx={{
          display: "flex",
          gap: 2,
          alignItems: "center",
          justifyContent: "center",
          py: 2,
          borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
        }}
      >
        <Controller
          control={control}
          name="transaction_type_id"
          render={({ field }) => (
            <FormControl sx={{ width: 180 }} size="small">
              <Select {...field}>
                {agency.info.transactions.map((x) => (
                  <MenuItem key={`trx_${x.id}`} value={x.id}>
                    {t(`transaction.type_${x.id}`)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        />

        <Controller
          control={control}
          name="property_type_id"
          render={({ field }) => (
            <FormControl sx={{ width: 180 }} size="small">
              <Select {...field}>
                <MenuItem value="all">{t("anything_property_type")}</MenuItem>
                {agency.info.property_types.map((x) => (
                  <MenuItem key={`trx_${x.id}`} value={x.id}>
                    {getTranslationFromArray(i18n.language, x.translations)
                      ?.name ?? x.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        />
        <Controller
          control={control}
          name="zone"
          render={({ field }) => (
            <FormControl sx={{ width: 180 }} size="small">
              <Select {...field}>
                <MenuItem value="all">{t("geo.all_locations")}</MenuItem>
                {zonesGrouped.map((group) => {
                  return [
                    <ListSubheader key={group.component.id}>
                      {group.component.computed_name}
                    </ListSubheader>,
                    ...group.zones.map((zone) => (
                      <MenuItem key={zone.id} value={zone.id}>
                        {zone.name}
                      </MenuItem>
                    )),
                  ];
                })}
              </Select>
            </FormControl>
          )}
        />
        <TextField
          {...register("reference")}
          sx={{ width: 180 }}
          label={t("filter.reference")}
          size="small"
        />
        <Button
          type="submit"
          variant="contained"
          color="primary"
          size="large"
          sx={{ px: 6 }}
        >
          {t("filter.search")}
        </Button>
      </Box>
    </form>
  );
}
