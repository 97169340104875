"use client";

import {
  Alert,
  Box,
  Button,
  Checkbox,
  Container,
  FormControlLabel,
  Grid,
  LinearProgress,
  Link,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { useCallback, useState } from "react";
import { useForm } from "react-hook-form";
import Lottie from "lottie-react";
import sendedOk from "../../lotties/91068-message-sent-successfully-plane.json";
import { GrStatusWarning } from "react-icons/gr";
import { useTranslation } from "next-i18next";
import { useAgency } from "../../hooks/use-agency";
import Trans from "../trans";

export default function ContactUS() {
  const agency = useAgency();
  const { t } = useTranslation();
  const hasContactData = agency.geo_address || agency.mobile || agency.email;
  return (
    <Container>
      <Grid container spacing={3}>
        <Grid item xs={12} md={hasContactData ? 8 : 12}>
          <ContactForm />
        </Grid>
        {hasContactData && (
          <Grid item xs={12} md={4}>
            <ContactUsInformation />
          </Grid>
        )}
      </Grid>
    </Container>
  );
}

function ContactForm() {
  const { t, i18n } = useTranslation();

  const [sendStatus, setSendStatus] = useState<
    "sending" | "sended" | "error" | null
  >(null);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: "",
      mobile: "",
      email: "",
      message: "",
      accept_legal_terms: false,
      accept_communications: false,
    },
  });

  const handleSendMessage = useCallback(
    async (form: any) => {
      setSendStatus("sending");

      try {
        const response = await fetch("/api/send-message", {
          method: "POST",
          body: JSON.stringify({ ...form, language_code: i18n.language }),
          headers: { "content-type": "application/json" },
        });
        setSendStatus(response.ok ? "sended" : "error");
      } catch (e) {
        setSendStatus("error");
      }
    },
    [i18n]
  );

  return (
    <form onSubmit={handleSubmit(handleSendMessage)}>
      <Paper
        sx={{
          p: 3,
        }}
      >
        <Typography variant="h5" gutterBottom>
          {t("contact.send_us_message")}
        </Typography>

        {sendStatus === "sended" && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Box sx={{ maxWidth: 300 }}>
              <Lottie animationData={sendedOk} loop={false} />
            </Box>
          </Box>
        )}

        {sendStatus !== "sended" && (
          <Box>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <TextField
                  {...register("name", {
                    required: t("validations.person_name.required") as string,
                  })}
                  disabled={sendStatus === "sending"}
                  required
                  error={!!errors.name}
                  helperText={errors.name?.message}
                  fullWidth
                  label={t("contact.person_name")}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  {...register("mobile", {
                    required: t("validations.mobile.required") as string,
                  })}
                  disabled={sendStatus === "sending"}
                  required
                  type="tel"
                  error={!!errors.mobile}
                  helperText={errors.mobile?.message}
                  fullWidth
                  label={t("contact.mobile")}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  {...register("email", {
                    required: t("validations.email.required") as string,
                  })}
                  disabled={sendStatus === "sending"}
                  required
                  type="email"
                  error={!!errors.email}
                  helperText={errors.email?.message}
                  fullWidth
                  label={t("contact.email")}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  {...register("message", {
                    required: t("validations.message.required") as string,
                  })}
                  disabled={sendStatus === "sending"}
                  required
                  error={!!errors.message}
                  helperText={errors.message?.message}
                  fullWidth
                  label={t("contact.message")}
                  multiline
                  minRows={4}
                />
              </Grid>
            </Grid>
            <Box sx={{ display: "flex", flexDirection: "column", my: 2 }}>
              <FormControlLabel
                disabled={sendStatus === "sending"}
                label={<Trans i18nKey="data_protection.check_accept_legal" />}
                required
                control={
                  <Checkbox
                    size="small"
                    {...register("accept_legal_terms", { required: true })}
                  />
                }
              />
              <FormControlLabel
                disabled={sendStatus === "sending"}
                label={
                  <Trans i18nKey="data_protection.check_accept_communications" />
                }
                control={
                  <Checkbox
                    size="small"
                    {...register("accept_communications")}
                  />
                }
              />
            </Box>
            {sendStatus === "sending" && <LinearProgress />}
            {sendStatus === "error" && (
              <Alert icon={<GrStatusWarning color="#fff" />} color="error">
                {t("contact.error")}
              </Alert>
            )}
            <Box sx={{ mt: 1 }}>
              <Button
                disabled={sendStatus === "sending"}
                type="submit"
                variant="contained"
                sx={{ px: 5 }}
              >
                {t("contact.send")}
              </Button>
            </Box>
          </Box>
        )}
      </Paper>
    </form>
  );
}

export function ContactUsInformation() {
  const agency = useAgency();
  const { t } = useTranslation();

  const hasContactData = agency.geo_address || agency.mobile || agency.email;

  if (!hasContactData) return null;

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
      {agency.mobile && (
        <Paper sx={{ p: 3 }}>
          <Typography variant="h5" gutterBottom>
            {t("contact.call_us")}
          </Typography>
          <Link
            href={`tel:${agency.mobile}`}
            sx={{
              position: "relative",
              fontSize: 28,
              textDecoration: "none",
              fontWeight: 700,
              color: "inherit",
              letterSpacing: 2,
              borderBottom: `1px solid #fff`,

              ":before": {
                content: '""',
                backgroundColor: "#000",
                width: "0%",
                height: "1px",
                position: "absolute",
                bottom: 0,
                left: "50%",
                transition: (theme) =>
                  theme.transitions.create(["left", "width"], {
                    duration: "0.5s",
                  }),
              },
              "&:hover": {
                ":before": {
                  left: "0%",
                  width: "100%",
                },
              },
            }}
          >
            {agency.mobile}
          </Link>
        </Paper>
      )}

      <Paper sx={{ p: 3 }}>
        <Typography variant="h5" gutterBottom>
          {t("contact.visit_us")}
        </Typography>
        <Box sx={{ display: "flex", flexDirection: "column", gap: 1.25 }}>
          {agency.geo_address && (
            <Box>
              <Link
                target="_blank"
                rel="noreferrer"
                href={`https://www.google.com/maps/place/${agency.geo_address.address
                  .split(" ")
                  .join("+")
                  .replace(/,/g, "")}/@${agency.geo_address.latitude},${
                  agency.geo_address.longitude
                }`}
              >
                {agency.geo_address.address}
              </Link>
            </Box>
          )}

          {agency.mobile && (
            <Typography>
              <Link href={`tel:${agency.mobile}`}>{agency.mobile}</Link>
            </Typography>
          )}
          {agency.email && (
            <Typography>
              <Link href={`mailto:${agency.email}`}>{agency.email}</Link>
            </Typography>
          )}
        </Box>
      </Paper>
    </Box>
  );
}
