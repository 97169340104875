import { HeroVariant1 } from "./variants/hero-1";
import { HeroVariant2 } from "./variants/hero-2";

export default function Hero(props: any) {
  const variant: "hero-1" | "hero-2" = "hero-1" as any;

  switch (variant) {
    case "hero-1":
      return <HeroVariant1 {...props} />;
    case "hero-2":
      return <HeroVariant2 {...props} />;
    default:
      throw new Error(`Unknown hero variant ${variant}`);
  }
}
