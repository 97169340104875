"use client";

import {
  Box,
  Button,
  Fab,
  Hidden,
  IconButton,
  Link,
  Typography,
  alpha,
} from "@mui/material";
import Searcher from "../../searcher";
import Slider, { Settings } from "react-slick";
import { PropertyProfile } from "@serviestate/shared";
import { getTranslation } from "../../../utils";
import { useTranslation } from "next-i18next";
import { usePropertyLink } from "../../../hooks/use-property-link";
import { useRef } from "react";
import { GrNext, GrPrevious } from "react-icons/gr";

const settings: Settings = {
  dots: false,
  arrows: false,
  speed: 1200,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: false,
  lazyLoad: "progressive",
};

export function HeroVariant1({
  properties,
}: {
  properties: PropertyProfile[];
}) {
  const ref = useRef<Slider>(null);

  if (properties.length === 0) return null;

  return (
    <Box>
      <Hidden mdDown>
        <Searcher />
      </Hidden>
      <Box sx={{ position: "relative" }}>
        {properties.length > 1 && (
          <>
            <Box
              sx={{
                position: "absolute",
                zIndex: 1,
                left: 0,
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                px: 2,
              }}
            >
              <Fab
                size="small"
                color="inherit"
                onClick={() => ref.current?.slickPrev()}
              >
                <GrPrevious size={20} />
              </Fab>
            </Box>
            <Box
              sx={{
                position: "absolute",
                zIndex: 1,
                right: 0,
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                px: 2,
              }}
            >
              <Fab
                size="small"
                color="inherit"
                onClick={() => ref.current?.slickNext()}
              >
                <GrNext size={20} />
              </Fab>
            </Box>
          </>
        )}

        <Slider ref={ref} {...settings}>
          {properties
            .filter(
              (x) =>
                x.multimedia &&
                x.multimedia.filter((c) => c.render_type === "image").length > 0
            )
            .map((property) => (
              <Box
                key={property.reference}
                sx={{
                  position: "relative",
                  height: "620px",
                  display: "flex",
                  alignItems: "center",
                  padding: "165px 0",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center center",
                  backgroundSize: "cover",
                  backgroundImage: `url(/api/documents/${
                    property.multimedia.find((x) => x.render_type === "image")
                      ?.document_id
                  }?fit=cover&width=1920&height=800&format=webp)`,
                }}
              >
                <SliderContent item={property} />
              </Box>
            ))}
        </Slider>
      </Box>
    </Box>
  );
}

function SliderContent({ item }: { item: PropertyProfile }) {
  const { t, i18n } = useTranslation();
  const translation = getTranslation(i18n.language, item.translations);
  const link = usePropertyLink(item);
  const attributes: string[] = [];
  const bedrooms = parseInt((item.attributes.bedrooms as string) ?? "0", 10);
  const bathrooms = parseInt((item.attributes.bathrooms as string) ?? "0", 10);
  const surface = parseInt((item.attributes.surface as string) ?? "0", 10);

  if (bedrooms > 0)
    attributes.push(
      bedrooms > 1
        ? `${bedrooms} ${t("attributes.bedrooms")}`
        : `1 ${t("attributes.bedroom")}`
    );

  if (bathrooms > 0)
    attributes.push(
      bathrooms > 1
        ? `${bathrooms} ${t("attributes.bathrooms")}`
        : `1 ${t("attributes.bathroom")}`
    );

  if (surface > 0) attributes.push(`${surface}m2`);

  return (
    <Box
      sx={{
        zIndex: 1,
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          zIndex: 0,
          position: "absolute",
          background:
            "linear-gradient(to top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 25%, rgba(0, 0, 0, 0.5) 50%, rgba(0, 0, 0, 0.5) 75%, rgba(0, 0, 0, 0) 100%)",
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          opacity: 1,
        }}
      />
      <Box
        sx={{
          position: "relative",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            position: "relative",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            gap: 3,
            color: "#fff",
          }}
        >
          {item.transactions.length > 0 && item.transactions[0].price && (
            <Typography variant="h3">
              {item.transactions[0].price.toLocaleString("de-DE")}€
            </Typography>
          )}
          <Typography variant="h4" textAlign="center">
            {translation?.title} {t("title_junction_location")}{" "}
            {item.zone?.computed_name}
          </Typography>
          <Box sx={{ display: "flex", gap: 3 }}>
            {attributes.map((x, i) => (
              <Typography fontSize={16} key={i}>
                {x}
              </Typography>
            ))}
          </Box>

          <Button
            size="large"
            variant="contained"
            href={link}
            sx={{ fontSize: 18 }}
          >
            {t("show_more")}
          </Button>
        </Box>
      </Box>
    </Box>
  );
}
